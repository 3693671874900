<script>
/**
 * Register component
 */
export default {
  data() {
    return {
      name: "",
      phone1: "",
      phone2: "",
      email: "",
      password: "",
      password_confirmation: "",
      isRegisterError: false,
      registerSuccess: false
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true
    },
    regError: {
      type: String,
      required: false,
      default: () => null
    }
  },
  mounted() {
    this.isRegisterError = !!this.regError;
  }
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card overflow-hidden">
        <!-- <div class="bg-soft-primary">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-4">
                <h5 class="text-primary">Registrate</h5>
                <p>Obtén tu cuenta para ingresar a las Listas de espera.</p>
              </div>
            </div>
            <div class="col-5 align-self-end">
              <img src="/images/profile-img.png" alt class="img-fluid" />
            </div>
          </div>
        </div> -->

        <div class="card-body pt-0">
          <div class="row mt-5 ">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <img class="" src="/images/logoic.jpg" alt="" width="50">
                </div>
              </div>
              <h4 class="text-center">Lista de Espera RED IC</h4>
              <label class="text-center p-4">Ingresa tus datos para crear tu cuenta. Verifica que los datos sean correctos, ya que en caso ser seleccionado se te contactará por uno de estos medios.</label>
            </div>
          </div>

          <b-alert
            v-model="registerSuccess"
            class="mt-3"
            variant="success"
            dismissible
          >Su registro ha sido un éxito, revise su correo y siga los pasos.</b-alert>

          <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{regError}}</b-alert>

          <b-form class="p-2" :action="submitUrl" method="POST">
              <slot />
            <b-form-group id="email-group" label="Nombre Completo" label-for="name">
              <b-form-input
                id="name"
                v-model="name"
                name="name"
                type="text"
                placeholder="Ingresar Nombre"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fullname-group" label="Teléfono 1" label-for="phone1">
              <b-form-input
                id="phone1"
                name="phone1"
                v-model="phone1"
                type="number"
                placeholder="Ingresar Teléfono"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="fullname-group" label="Teléfono 2" label-for="phone2">
              <b-form-input
                id="phone2"
                name="phone2"
                v-model="phone2"
                type="number"
                placeholder="Ingresar Teléfono"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fullname-group" label="Correo Electrónico" label-for="email">
              <b-form-input
                id="email"
                name="email"
                v-model="email"
                type="email"
                placeholder="Ingresar Correo"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="password-group" label="Contraseña" label-for="password">
              <b-form-input
                id="password"
                v-model="password"
                name="password"
                type="password"
                placeholder="Ingresar contraseña"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Confirma Contraseña" label-for="password-confirm">
              <b-form-input
                id="password-confirm"
                v-model="password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirmar contraseña"
              ></b-form-input>
            </b-form-group>

            <div class="mt-4">
              <b-button type="submit" variant="primary" class="btn-block">Registrarse</b-button>
            </div>

            <!-- <div class="mt-4 text-center">
              <p class="mb-0">
                Al registrarte estás de acuerdo con 
                <a
                  href="javascript: void(0);"
                  class="text-primary"
                >Términos y condiciones</a>
              </p>
            </div> -->
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center">
        <p>
          ¿Ya tienes cuenta?
          <a href="/login" class="font-weight-medium text-primary">Inicia sesión</a>
        </p>
        <!-- <p>
          © {{ new Date().getFullYear() }} Skote. Crafted with
          <i class="mdi mdi-heart text-danger"></i> by Themesbrand
        </p> -->
      </div>
    </div>
    <!-- end col -->
  </div>
</template>

<style lang="scss" module></style>
