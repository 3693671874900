<script>
import simplebar from "simplebar-vue";

export default {
  components: {
    simplebar
  },
  methods: {
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/images/logos/favicon.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logos/logoalegoria.png" alt height="40" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/images/logos/favicon.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logos/logoalegoria.png" alt height="35" />
            </span>
          </a>
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm mr-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        

        
      </div>

      <div class="d-flex">
        

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <span class="d-none d-xl-inline-block ml-1"><i class="bx bx-user font-size-20"></i> {{user.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <!-- <a class="dropdown-item" href="#">
            <i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile
          </a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Salir
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
