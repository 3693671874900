<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
import { validateRut } from '@fdograph/rut-utilities';
//
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, VueFormWizard: FormWizard, TabContent, Swal },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            curso:'',
            colegio:'',
            cursos:[],
            colegios:[],
            inscritos:[],
            nuevo:false,
            datos:{},
            rut:'',
            ru_dv:'',
            valido:false,
            key:0,
            key1:0,
            ranking:'',
            fields: [
                { key: "rut", label:'Rut',sortable: true },
                { key: "nombre", label:'Nombre',sortable: true },
                { key: "apellido_paterno", label:'Apellido Paterno',sortable: true },
                { key: "apellido_materno", label:'Apellido Materno',sortable: true },
                { key: "ranking", label:'Ranking',sortable: true },
                { key: "curso", label:'Curso',sortable: true },
                { key: "colegio", label:'Colegio',sortable: true },
                { key: "acciones", label:'Acciones',sortable: true }
            ],
            sortBy: "fecha",
            sortDesc: true,
            motivo:'',
            data_eliminar:{},
            data_editar:{},
            inscripciones:[],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#34c38f'],
                grid: {
                    borderColor: '#f1f1f1',
                },
                xaxis: {
                    categories: ["1er nivel de Transición","2° nivel de Transición","1° básico","2° básico","3° básico","4° básico","5° básico","6° básico","7° básico","8° básico","1° medio","2° medio","3° medio","4° medio"]
                },
                title: {
                    text: 'Postulaciones por curso',
                    align: 'bottom',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '14px',
                        fontWeight:  'bold',
                        fontFamily:  undefined,
                        color:  '#000000'
                    },
                },
            },
            series: [],
            json_fields: {
                'Rut': 'rut',
                'Nombres': 'nombre',
                'Apellido Paterno': 'apellidop',
                'Apellido Materno' : 'apellidom',
                'Ranking' : 'ranking',
                'Curso' : 'curso',
                'Colegio' : 'colegio',
                'Contacto' : 'nombre_contacto',
                'Telefono1' : 'telefono1',
                'Telefono2' : 'telefono2',
            },
            json_data:[],
            icono:'',
            titulo:'',
            texto:'',
            texto2:'',
        };
    },
    watch:{
        
    },
    methods:{
        agregar_nuevo(){
            this.nuevo=true;
        },
        beforeTabSwitch1(){
            //return true;//eliminar para validar
            if (this.colegio!='' && this.curso!=''){
                return true;
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Faltan campos por completar',
                    //text: 'Su reserva se completo con éxito ',
                    timer: 2500
                }).then(()=>{
                    return
                });
            }
        },
        beforeTabSwitch2(){
            //return true; //eliminar para validar campos
            if (this.valido==true && this.datos.nombres!=''&& this.datos.apellido_paterno!='' && this.datos.apellido_materno!=''){
                this.guardar_alumno();
                return true;
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Faltan campos por completar',
                    //text: 'Su reserva se completo con éxito ',
                    timer: 2500
                }).then(()=>{
                    return
                });
            }
        },
        curso_select(){
            let cursosel;
            this.cursos.forEach(element => {
                if (element.id ==this.curso) {
                    cursosel = element.descripcionGrado;
                }
            });
            return cursosel;
        },
        colegio_select(){
            let colegiosel;
            this.colegios.forEach(element => {
                if (element.id ==this.colegio) {
                    colegiosel = element.nombre;
                }
            });
            return colegiosel;
        },
        guardar_alumno(){
            let payload= {
                rut:this.datos.rut,
                nombres:this.datos.nombres,
                apellido_paterno:this.datos.apellido_paterno,
                apellido_materno:this.datos.apellido_materno,
                info_adicional:this.datos.info_adicional,
                colegio: this.colegio,
                curso: this.curso,
                nombre_contacto: this.datos.nombre_contacto,
                telefono1: this.datos.telefono1,
                telefono2: this.datos.telefono2
            }
            axios.post('/api/guardar_datos_alumno',payload).then(res=>{
                if(res.data.estado){
                    this.ranking = res.data.inscripcion.ranking;
                    //this.matriculadamsg(this.alumno.name, this.alumno.apellidoPaterno, this.alumno.apellidoMaterno);
                    axios.get('/api/get_inscritos').then(res=> {
                        this.inscritos = res.data.inscritos;
                    });
                    this.icono = 'mdi mdi-check-all'
                    this.titulo = '¡Sus datos han sido registrado!'
                    this.texto = 'Usted quedó en el Nº'+this.ranking+' de la lista de espera del curso '+this.curso_select()+' en '+this.colegio_select()
                    this.texto2 = 'A su correo llegará un mensaje con la postulación.'
                    this.actualizar();
                }else{
                    if(res.data.inscripcion==''){
                        Swal.fire({
                            icon: 'error',
                            title: 'Ya ha agregado el máximo permitido',
                            //text: 'En el curso '+this.curso_select+' y el colegio '+this.colegio_select,
                            timer: 2500
                        }).then(()=>{
                            this.cargar()
                            return
                        });
                    }else{
                        this.ranking = res.data.inscripcion.ranking;
                        console.log(res.data.inscripcion);
                        console.log(res.data.mensaje);
                        Swal.fire({
                            icon: 'error',
                            title: 'El alumno ya fue inscrito este año',
                            //text: 'En el curso '+this.curso_select+' y el colegio '+this.colegio_select,
                            timer: 2500
                        }).then(()=>{
                            this.icono = 'mdi mdi-close'
                            this.titulo = res.data.mensaje
                            if (this.user.id==res.data.inscripcion.user_id) {
                                this.texto = 'El alumno está en el Nº'+this.ranking+' de la lista de espera del curso '+this.curso_select()+' en '+this.colegio_select()
                            }
                            this.texto2 = ''
                            return
                        });
                        
                    }
                }
            });
        },
        siguiente(){
            this.$refs.formWiz.nextTab()
        },
        atras(){
            this.$refs.formWiz.prevTab()
        },
        cargar(){
            axios.get('/api/get_inscritos').then(res=> {
                this.inscritos = res.data.inscritos;
            });
            this.colegio=this.user.colegio_id;
            this.curso='';
            this.datos.rut='';
            this.datos.nombres='';
            this.datos.apellido_paterno='';
            this.datos.apellido_materno='';
            this.datos.info_adicional='';
            this.datos.nombre_contacto='';
            this.datos.telefono1='';
            this.datos.telefono2='';
            this.valido=false;
            this.key = this.key+1;
        },
        modal_eliminar(data){
            this.$refs['eliminar-modal'].show()
            this.data_eliminar = data;
        },
        eliminar(){
            Swal.fire({
                title: "¿Esta seguro?", 
                text: "Si elimina la inscripción pierde el puesto en el que quedó el alumno",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Eliminar inscripción",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    let payload= this.data_eliminar;
                    payload.motivo_eliminacion = this.motivo;
                    axios.post('/api/eliminar',payload).then(res=>{
                        Swal.fire({
                            icon: 'success',
                            title: 'El alumno ya fue eliminado de la lista de espera',
                            timer: 2500
                        }).then(()=>{
                            return
                        });
                        this.actualizar();
                    });
                    this.$refs['eliminar-modal'].hide();
                    axios.get('/api/get_inscritos').then(res=> {
                        this.inscritos = res.data.inscritos;
                    });
                }
            });
        },
        modal_editar(data){
            this.$refs['editar-modal'].show()
            this.data_editar = data;
            this.valido=true;
        },
        editar(){
            let payload= this.data_editar;
            axios.post('/api/editar',payload).then(res=>{
                Swal.fire({
                    icon: 'success',
                    title: 'Se editaron los datos del alumno',
                    timer: 2500
                }).then(()=>{
                    return
                });
            });
            this.valido=false;
            this.$refs['editar-modal'].hide();
            axios.get('/api/get_inscritos').then(res=> {
                this.inscritos = res.data.inscritos;
            });
        },
        ver_rut(rut){
            let val = validateRut(rut);
            this.valido=val;
        },
        actualizar(){
            if (this.user.colegio_id!=null) {
                axios.get('/api/analisis/'+this.user.colegio_id).then(res=> {
                    this.inscripciones = res.data.inscripciones;
                    this.json_data = res.data.json_data;
                    this.series= [{
                            name: "Cursos",
                            data: this.inscripciones.data//[ 0, 0, 0, 0, 0, 0, 6, 258, 349, 283, 230, 28 ]
                        }]
                });
            }
        }
    },
    created(){
        axios.get('/api/get_cursos').then(res=> {
            this.cursos = res.data.cursos;
        });
        axios.get('/api/get_colegios').then(res=> {
            this.colegios = res.data.colegios;
            if (this.user.colegio_id!=null) {
                this.colegios.forEach(element => {
                    if (element.id==this.user.colegio_id) {
                        this.colegios=[];
                        this.colegios.push(element);
                        this.colegio = this.user.colegio_id;
                    }
                });
                
            }
        });
        axios.get('/api/get_inscritos').then(res=> {
            this.inscritos = res.data.inscritos;
        });
        if (this.user.colegio_id!=null) {
            axios.get('/api/analisis/'+this.user.colegio_id).then(res=> {
                this.inscripciones = res.data.inscripciones;
                this.json_data = res.data.json_data;
                this.series= [{
                        name: "Cursos",
                        data: this.inscripciones.data//[ 0, 0, 0, 0, 0, 0, 6, 258, 349, 283, 230, 28 ]
                    }]
            });
        }
        
    }
};
</script>
<template>
    <Layout>
        <!-- <PageHeader :title="title" :items="items" /> -->
        <div class="row">
            <div class="col-md-12" v-if="user.colegio_id!=null">
                <div class="row">
                    <div class="col-md-2">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body">
                                        <p class="text-black">Total Alumnos</p>
                                        <h4 class="mb-0">{{inscripciones.total}}</h4>
                                    </div>
                                    <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i :class="`bx bx-group font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <export-excel
                            class   = "btn btn-primary btn-sm btn-block"
                            :data   = "json_data"
                            :fields = "json_fields"
                            worksheet = "Hoja 1"
                            :name    = "'Postulaciones.xls'"
                            >
                            Exportar Lista de Espera
                        </export-excel>
                    </div>
                    <div class="col-md-10">
                        <div class="card">
                            <div class="card-body">
                                <apexchart
                                class="apex-charts"
                                height="350"
                                type="bar"
                                :series="series"
                                :options="chartOptions"
                                ></apexchart>
                            </div>
                        </div>
                        <!-- <barchart :title="'Ingreso por curso'" :data="inscripciones.data" :categories="inscripciones.categories"/> -->
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title text-center">Bienvenidos a la Lista de espera 2023</h2>
                        <p>Texto con las instrucciones</p>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3" v-if="inscritos.length>=0 && nuevo==true">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <vue-form-wizard color="#556ee6" ref="formWiz" nextButtonText="Siguiente" backButtonText="Atrás" finishButtonText="Finalizar" :hide-buttons="true" :key="key">
                                    <tab-content icon="mdi mdi-account-circle" :before-change="beforeTabSwitch1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="3" label="Colegio :" label-for="text" >
                                                    <select class="form-control" v-model="colegio" >
                                                        <option value="">Seleccionar Colegio</option>
                                                        <option v-for="(colegio, index) in colegios" :value="colegio.id" :key="index">
                                                            {{ colegio.nombre }}
                                                        </option>
                                                    </select>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="3" label="Curso :" label-for="text" >
                                                    <select class="form-control" v-model="curso" >
                                                        <option value="">Seleccionar curso</option>
                                                        <option v-for="(curso, index) in cursos" :value="curso.id" :key="index">
                                                            {{ curso.descripcionGrado }}
                                                        </option>
                                                    </select>
                                                </b-form-group>
                                            </div> 
                                        <!-- end col -->
                                            <div class="col-md-12 mt-3">
                                                <div class="row">
                                                    <div class="offset-md-10 col-md-2">
                                                        <b-button class="float-right" block variant="primary" @click="siguiente()">Siguiente</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end row -->
                                    </tab-content>
                                    <tab-content icon="mdi mdi-face-profile" :before-change="beforeTabSwitch2">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p class="font-size-16"><b>Colegio:</b> {{ colegio_select() }}</p>
                                                <p class="font-size-16"><b>Curso:</b> {{ curso_select() }}</p>
                                                
                                            </div>
                                            <div class="col-md-12">
                                                <p class="float-right">(*) campos obligatorios</p>
                                            </div>
                                            <div class="col-12" v-if="this.user.colegio_id!=null">
                                                <h4>Datos de contacto</h4>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="name">Nombre del Contacto (*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="name" class="form-control" v-model="datos.nombre_contacto"/>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="name">Telefono 1 (*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="name" class="form-control" v-model="datos.telefono1"/>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="name">Telefono 2</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="name" class="form-control" v-model="datos.telefono2"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <h4>Datos del alumno</h4>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="name">Rut Alumno(*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="name" class="form-control" v-model="datos.rut" @input="ver_rut(datos.rut)"/>
                                                        <p class="p-0 m-0 text-danger" v-if="!this.valido">Rut no valido</p>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="surname">Nombres (*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="surname" class="form-control" v-model="datos.nombres" />
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="surname">Apellido Paterno (*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="surname" class="form-control" v-model="datos.apellido_paterno" />
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="surname">Apellido Materno (*)</label>
                                                    <div class="col-md-9">
                                                        <input type="text" name="surname" class="form-control" v-model="datos.apellido_materno" />
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-3 col-form-label" for="surname">Información Adicional</label>
                                                    <div class="col-md-9">
                                                        <textarea class="form-control" v-model="datos.info_adicional" ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <b-button class="float-left" block variant="primary" @click="atras()">Atrás</b-button>
                                                    </div>
                                                    <div class="offset-md-8 col-md-2">
                                                        <b-button class="float-right" block variant="primary" @click="siguiente()">Finalizar</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- end col -->
                                        </div>
                                        <!-- end row -->
                                    </tab-content>
                                    <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                <h2 class="mt-0">
                                                    <i :class="icono"></i>
                                                </h2>
                                                <h3 class="mt-0">{{titulo}}</h3>
                                                <p class="w-75 mb-2 mx-auto font-size-16">
                                                    {{ texto }}
                                                </p>
                                                <p class="w-75 mb-2 mx-auto">
                                                    {{texto2}}
                                                </p>
                                                <!-- <div class="mb-3">
                                                    <div class="custom-control custom-checkbox">
                                                    <input id="customCheck1" type="checkbox" class="custom-control-input" />
                                                    <label
                                                        class="custom-control-label"
                                                        for="customCheck1"
                                                    >I agree with the Terms and Conditions</label>
                                                    </div>
                                                </div> -->
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <b-button class="float-right" variant="primary" @click="cargar()">Agregar otro alumno</b-button>
                                            </div>
                                        <!-- end col -->
                                        </div>
                                        <!-- end row -->
                                    </tab-content>
                                </vue-form-wizard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3" v-else>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <b-button variant="success" @click="agregar_nuevo()">Agregar nuevo alumno</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="inscritos.length>0">
                <div class="card">
                    <div class="card-body">
                        <p>Usted a inscrito a:</p>
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="inscritos"
                                :fields="fields"
                                responsive="sm"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                            >
                                <template v-slot:cell(rut)="data">
                                    <p>{{ data.item.rut}}</p>
                                </template>
                                <template v-slot:cell(nombre)="data">
                                    <p>{{ data.item.nombres}}</p>
                                </template>
                                <template v-slot:cell(acciones)="data">
                                    <b-button variant="primary" size="sm" @click="modal_editar(data.item)"><i class="bx bx-pencil"></i></b-button>
                                    <b-button variant="primary" size="sm" @click="modal_eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <b-modal ref="eliminar-modal" title="Motivo" cancel-title="Cancelar" ok-title="Eliminar" @ok="eliminar">
                            <b-form-textarea id="textarea" v-model="motivo" placeholder="Ingresar justifición" rows="3" max-rows="6"  ></b-form-textarea>
                        </b-modal>
                        <b-modal ref="editar-modal" title="Editar datos alumno" cancel-title="Cancelar" ok-title="Editar" @ok="editar">
                            <div class="row">
                                <div class="col-12" v-if="this.user.colegio_id!=null">
                                    <h4>Datos de contacto</h4>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Nombre del Contacto</label>
                                        <div class="col-md-9">
                                            <input type="text" name="name" class="form-control" v-model="data_editar.nombre_contacto"/>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Telefono 1</label>
                                        <div class="col-md-9">
                                            <input type="text" name="name" class="form-control" v-model="data_editar.telefono1"/>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Telefono 2</label>
                                        <div class="col-md-9">
                                            <input type="text" name="name" class="form-control" v-model="data_editar.telefono2"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h4>Datos del alumno</h4>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="name">Rut Alumno</label>
                                        <div class="col-md-9">
                                            <input type="text" name="name" class="form-control" v-model="data_editar.rut" @input="ver_rut(data_editar.rut)"/>
                                            <p class="p-0 m-0 text-danger" v-if="!this.valido">Rut no valido</p>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="surname">Nombres</label>
                                        <div class="col-md-9">
                                            <input type="text" name="surname" class="form-control" v-model="data_editar.nombres" />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="surname">Apellido Paterno</label>
                                        <div class="col-md-9">
                                            <input type="text" name="surname" class="form-control" v-model="data_editar.apellido_paterno" />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="surname">Apellido Materno</label>
                                        <div class="col-md-9">
                                            <input type="text" name="surname" class="form-control" v-model="data_editar.apellido_materno" />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-3">
                                        <label class="col-md-3 col-form-label" for="surname">Información Adicional</label>
                                        <div class="col-md-9">
                                            <textarea class="form-control" v-model="data_editar.info_adicional" ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>